/**
 * @file It contains scripts for Reset Password page
 */

import { mapActions, mapGetters } from "vuex"

export default {
  name: "ResetPasswordPage",
  data() {
    return {
      resetPasswordOptions: {
        username: this.$route.query.user,
        code    : this.$route.query.code,
        password: undefined
      },
      showPasswordRules : false,
      showPassword      : false,
      passwordValidation: {
        minimumCharactersPresent         : false,
        minimumNumbersPresent            : false,
        minimumUpperCaseCharactersPresent: false,
        minimumLowerCaseCharactersPresent: false,
        minimumSpecialCharactersPresent  : false
      },
      isPasswordValid: false
    }
  },
  computed: {
    ...mapGetters({
      clientName         : "auth/clientName",
      isResettingPassword: "auth/isResettingPassword",
      isPasswordReset    : "auth/isPasswordReset",
      isPasswordExpired  : "auth/isPasswordExpired"
    })
  },
  methods: {
    ...mapActions({
      "resetPassword": "auth/resetPassword",
      "notify"       : "shared/notify"
    })
  },
  watch: {
    isPasswordReset: function(newValue) {
      if (newValue) {
        this.notify({
          type: "success",
          text: "429"
        })
        this.$router.push({ name: "login" })
      }
    },
    isPasswordExpired: function(newValue) {
      if (newValue) {
        this.notify({
          type: "error",
          text: "428"
        })
        this.$router.push({ name: "login" })
      }
    },
    "resetPasswordOptions.password": function(newValue) {
      this.passwordValidation = {
        minimumCharactersPresent         : newValue?.length >= 12,
        minimumNumbersPresent            : newValue?.match(/\d/),
        minimumUpperCaseCharactersPresent: newValue?.match(/[A-Z]/),
        minimumLowerCaseCharactersPresent: newValue?.match(/[a-z]/),
        minimumSpecialCharactersPresent  : newValue?.match(/[!@#$%^&*]/)
      }
      this.isPasswordValid    = this.passwordValidation.minimumCharactersPresent &&
         this.passwordValidation.minimumNumbersPresent &&
         this.passwordValidation.minimumUpperCaseCharactersPresent &&
         this.passwordValidation.minimumLowerCaseCharactersPresent &&
         this.passwordValidation.minimumSpecialCharactersPresent
    }
  }
}